<template>
  <div class="sidemenu">
    <!---<div class="side-search">
            <Search/>
        </div>-->
    <div class="banner-c">
      <a :href="banner.link" target="_blank" rel="noopener noreferrer">
        <img
          :src="
            urlpath.url() +
            '/get-banner-image/' +
            banner.image
          " alt="banner">
      </a>
    </div>
    <!--<div class="fb-widget">
      <iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fes-la.facebook.com%2Frevistafoodie&tabs=timeline&width=500&height=500&small_header=true&adapt_container_width=true&hide_cover=false&show_facepile=true&appId" width="100%" height="500" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
    </div>-->
    <div class="row-widget">
      <p class="titulo">ENTRADAS RECIENTES</p>
      <div class="list dflx jcsb aic" v-for="(b,i) in blogs" :key="i">
        <a :href="'/blog-post/'+b.url">
          <p>{{b.title}}</p>
        </a>
        <p>></p>
      </div>
    </div>
    <div class="row-widget" v-if="category.length>0">
      <p class="titulo">CATEGORÍAS</p>
      <div class="list dflx jcsb aic" v-for="(b,i) in category" :key="i">
        <a :href="'/category/'+b.id">
          <p>{{b.name}}</p>
        </a>
        <p>></p>
      </div>
    </div>

    <div class="banner-c" style="margin-top: 10rem;" v-if="bannerBlog.link">
      <a :href="bannerBlog.link" target="_blank" rel="noopener noreferrer">
        <img
          :src="
            urlpath.url() +
            '/get-banner-image/' +
            bannerBlog.image
          " alt="banner">
      </a>
    </div>


  </div>
</template>
<script>
import { mapActions } from "vuex";
let urlpath = require("../../global/url");
export default {
  data() {
    return {
      urlpath,
    };
  },
  created() {
    this.getAllInfoBlo2("blog");
    this.getAllInfoCat("category");
    this.getAllInfoBnr("banner");
  },
  computed: {
    banner() {
      let arr = this.$store.getters["banner/data"];
      if (arr == "No se encontraron coincidencias") {
        return "No se encontraron coincidencias.";
      } else {
        let array = arr.filter((arr) => arr.section == "sidemenu");
        return array[0];
      }
    },
    bannerBlog() {
      let arr = this.$store.getters["banner/data"];
      if(!Array.isArray(arr)) return []

      let option = 'entrada'
      if(this.$route.name === 'Feed'){
        option = 'blog'
      }

      let blog = arr.filter((arr) => arr.section == option);
      return blog.length>0 ? blog[0] : [];
    },
    category() {
      const data = this.$store.getters["category/data"];
      if(!Array.isArray(data)) return []
      return data
    },
    blogs() {
      const data = this.$store.getters["blog/data2"];
      if(!Array.isArray(data)) return []
      
      return data.filter(item => item.statusblog ==='VISIBLE').splice(0,5);
    },
  },
  methods: {
    ...mapActions("banner", ["getAllInfoBnr"]),
    ...mapActions("blog", ["getAllInfoBlo2"]),
    ...mapActions("category", ["getAllInfoCat"]),
  },
};
</script>
<style>
@media (max-width:768px){
  .widget-c{
  padding: 0 10%;
}
.sidemenu .banner-c img{
    width: 80%;
    padding-left: 10%;
}
p.titulo{
  font-weight: 800;
  display: flex;
  justify-content: center;
  border-top: 1px solid #ccc;
  padding-top: 5vw;
  font-size: 6vw;
}
.row-widget .list p {
    font-size: 5vw;
    line-height: normal;
    color: gray;
    font-family: var(--RedHat);
    font-weight: 500;
    transition: .5s;
}
}

@media (min-width:768px) {
    .fb-widget {
        overflow: hidden;
    width: 17vw;
    /*height: 15.052vw;*/
    height: 25.052vw;
    padding-bottom: 2.083vw;
}
.sidemenu .banner-c {
    width: 17vw;
    padding-bottom: 2vw;
}
.sidemenu .banner-c img{
    width: 100%;
    padding-left: 0%;
}
.row-widget{
    width: 17.292vw;
}
.row-widget .list {
    border-bottom: 1px solid gainsboro;
}
.row-widget .titulo {
    font-size: 0.885vw!important;
    line-height: 0.938vw;
    margin-bottom: 1.042vw;
    padding-bottom: 0;
    font-weight: 700;
    text-transform: uppercase;
    color: #111;
    font-family: var(--RedHat);
}
.row-widget .list p{
    font-size: 0.677vw;
    line-height: 1.198vw;
    color: gray;
    font-family: var(--RedHat);
    font-weight: 500;
    transition: .5s;
}
.row-widget .list p:hover{
    color: rgb(0, 0, 0);
}
.row-widget .list a{
  width: 15vw;
}
}
</style>